
















































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from 'vuex-class'
import VueMarkdownIt from "vue-markdown-it";
import VueMarkdown from 'vue-markdown-render'

const cookies = require('js-cookie')

const SettingsGetter = namespace('settings').Getter
const AccountAction = namespace("account").Action
const AccountGetter = namespace("account").Getter
const BonusWagerGetter = namespace('bonusWager').Getter
const AvailablePromotionsCampaignGetter = namespace('availablePromotionsCampaign').Getter
const AvailablePromotionsCampaignAction = namespace('availablePromotionsCampaign').Action
const FreeSpinsAction = namespace('freeSpins').Action
const FreeSpinsGetter = namespace('freeSpins').Getter
const ActivePromotionsCampaignAction = namespace('activePromotionsCampaign').Action
const ActivePromotionsCampaignGetter = namespace('activePromotionsCampaign').Getter
const PastPromotionsCampaignAction = namespace('pastPromotionsCampaign').Action
const PastPromotionsCampaignGetter = namespace('pastPromotionsCampaign').Getter

  const AppProps = Vue.extend({
  props: ['section', 'tournament'],
  data: function() {
    return {
      tournaments: [],
      casino_code: cookies.get('casino_code'),
      VUE_APP_STRAPI_API_URL: process.env.VUE_APP_STRAPI_API_URL
    }
  },
  async mounted() {},
  methods: {
    onClickLink() {},
    handleShowLeaderboard() {
      this.$emit('show-leaderboard', this.tournament.tournamentId);
    }
  },
  beforeDestroy: function() {
    clearInterval(this.interval);
  }
});

@Component({
components: {
  VueMarkdownIt,
  VueMarkdown
},
})
export default class Tournaments extends AppProps {
  @SettingsGetter getPlatformCurrency!: String
  @AccountAction getUserData
  @AccountAction getDepositAddress
  @AccountGetter emailVerified
  @AccountGetter isLoggedIn: boolean
  @BonusWagerGetter getBonusClaimTotalWager
  @BonusWagerGetter getBonusClaimMaxWager
  @BonusWagerGetter getIsAvailableBonus
  @AvailablePromotionsCampaignGetter getAvailablePromotionsContent
  @AvailablePromotionsCampaignGetter getAvailablePromotionsContentWithoutOptInDate
  @AvailablePromotionsCampaignGetter getAvailablePromotionsContentWithOptInDate
  @AvailablePromotionsCampaignGetter getAutoOptInCampaign
  @AvailablePromotionsCampaignAction claimPromotionUsingBannerId
  @AvailablePromotionsCampaignAction getAvailablePromotions
  @AvailablePromotionsCampaignAction getGeneralPromotions
  @AvailablePromotionsCampaignAction claimPromotionCampaign
  @FreeSpinsAction getFreeSpinsCampaignWise
  @FreeSpinsGetter getFreeSpinsConnectedWithCampaign
  @ActivePromotionsCampaignAction getActiveBonuses
  @ActivePromotionsCampaignGetter getActiveBonusData
  @ActivePromotionsCampaignGetter getActiveBonusAuthorUUID
  @ActivePromotionsCampaignGetter isAnyPromotionActive
  @PastPromotionsCampaignAction getPastBonuses
  @PastPromotionsCampaignAction deletePastPromo
  @PastPromotionsCampaignGetter getPastBonusData
}
