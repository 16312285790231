



































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from 'vuex-class'
import moment from "moment";
import gql from 'graphql-tag'
import VueMarkdownIt from "vue-markdown-it";
import apolloClient from "@/vue-apollo";
import VueMarkdown from 'vue-markdown-render'
import Tournaments from "@/components/panels/Tournaments.vue";

const cookies = require('js-cookie')

const SettingsGetter = namespace('settings').Getter
const AccountAction = namespace("account").Action
const AccountGetter = namespace("account").Getter
const BonusWagerGetter = namespace('bonusWager').Getter
const AvailablePromotionsCampaignGetter = namespace('availablePromotionsCampaign').Getter
const AvailablePromotionsCampaignAction = namespace('availablePromotionsCampaign').Action
const FreeSpinsAction = namespace('freeSpins').Action
const FreeSpinsGetter = namespace('freeSpins').Getter
const ActivePromotionsCampaignAction = namespace('activePromotionsCampaign').Action
const ActivePromotionsCampaignGetter = namespace('activePromotionsCampaign').Getter
const PastPromotionsCampaignAction = namespace('pastPromotionsCampaign').Action
const PastPromotionsCampaignGetter = namespace('pastPromotionsCampaign').Getter
const DepositAction = namespace("deposit").Action
const DepositGetter = namespace("deposit").Getter

const AppProps = Vue.extend({
  props: {},
  data: function() {
    return {
      isSpinner: true,
      interval: null,
      tournamentsAvailable: false,
      tournaments: [],
      tournamentData: {},
      casino_code: cookies.get('casino_code'),
      redirectToAfterClaim: '',
      claimPromoSpinnerIndex: -1,
      VUE_APP_STRAPI_API_URL: process.env.VUE_APP_STRAPI_API_URL,
      leaderboardData: [],
      isMobile: /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(navigator.userAgent),
      weekDays: {
        'Sunday': 0,
        'Monday': 1,
        'Tuesday': 2,
        'Wednesday': 3,
        'Thursday': 4,
        'Friday': 5,
        'Saturday': 6,
      }
    }
  },
  filters: {
    localizedButtonText(promotion, locale) {
      const buttonTextLocalized = promotion['buttonText_' + locale]
      return (locale !== 'en' && buttonTextLocalized) ? buttonTextLocalized : promotion.buttonText
    },
    localizedRequirements(promotion, locale) {
      const requirementsTextLocalized = promotion['requirements_' + locale]
      return (locale !== 'en' && requirementsTextLocalized) ? requirementsTextLocalized : promotion.requirements
    }
  },
  apollo: {
    tournaments: {
      // GraphQL Query
      query: gql`
        query Tournaments($casinoCode: String!) {
          casinos(where: { name: $casinoCode }) {
            tournaments {
              id
              tournamentId
              status
              description
              description_ru
              description_es
              description_pt
              buttonLink
              buttonText
              buttonText_ru
              buttonText_es
              buttonText_pt
              order
              requirements
              requirements_ru
              requirements_es
              requirements_pt
              is_disabled
              is_leaderboard
              terms_and_conditions
              terms_and_conditions_ru
              terms_and_conditions_es
              terms_and_conditions_pt
              image {
                url
              }
              image_ru {
                url
              }
              image_es {
                url
              }
              image_pt {
                url
              }
            }
          }
        }
    `,
    update: data => {
        let tournaments = data.casinos[0].tournaments.sort( (a, b) => parseInt(a.order) - parseInt(b.order) );
        return tournaments;
      },
    variables () {
      return {
        casinoCode: this.casino_code || (window.location.host.includes("cashgames") ? "cashgames" : "games"),
      }
    },
    },
  },
  metaInfo: {
    meta: [{
      vmid: 'description',
      name: 'description',
      content: `Get 3 BTC in Welcome Bonus, Get Free Spins, Cashback & more in regular Promotions  from the best Bitcoin Games Casino`
    },
    {
      vmid: 'title',
      name: 'title',
      content: `Welcome Bonus`
    },
    {
      vmid: 'keywords',
      name: 'keywords',
      content: `bitcoin casino, btc casino, bch casino, crypto casino, bitcoin gambling, bitcoin casino no deposit, crypto casino no deposit, best bitcoin casino, bitcoin games`
    }],
  },
  beforeDestroy: function() {
    clearInterval(this.interval);
  },
  async mounted() {
    if(this.$route.query.active && this.isLoggedIn) this.goToActive()
    if(this.$route.hash) {
      this.$refs['tab-content'].querySelector('.tab-pane.active').classList.remove('active')
      this.$refs['tab-content'].querySelector(this.$route.hash).classList.add('active')
      this.$refs['nav-link'].querySelector('li > .nav-link.active').classList.remove('active')
      this.$refs['nav-link'].querySelector(this.$route.hash + '_link').classList.add('active')
    }
    await this.getUserData()
    this.isSpinner = true;
    if (this.isLoggedIn) {
      await this.getAvailablePromotions(cookies.get('banner_id'))
      await this.getActiveBonuses()
      await this.getPastBonuses()
      this.isSpinner = false
      await this.getTempXMasPromotion();
      //Fetching the active bonuses at every 5 sec.
      let that = this;
      this.interval = setInterval(async function () {
        await that.getActiveBonuses();
        await that.getTempXMasPromotion();
      }, 20000);
    } else {
      await this.getGeneralPromotions()
      this.isSpinner = false
    }
  },
  watch: {
    filteredTournaments: {
      handler: function(newVal) {
        if (newVal && newVal.length) {
          this.tournamentsAvailable = true;
        }
      }
    },
    getAvailablePromotionsContentWithOptInDate: {
      handler: function() {
        this.checkWaitingForDepositsCampaigns();
      },
      immediate: true,
    },
    getAvailablePromotionsContent: {
      handler: function() {
        this.checkSpecificDayPromotion();
      },
      immediate: true,
    }
  },
  computed: {
    filteredTournaments: function() {
        let temp_tournaments = [];
        for(let i=0; i<this.tournaments.length; i++) {
            if(this.showTournaments(this.tournaments[i].id)) {
              temp_tournaments.push(this.tournaments[i]);
            }
        }
        return temp_tournaments;
    }
  },
  methods: {
    async handleShowLeaderboard(tournamentId: string) {
      this.leaderboardData = [];
      this.$modal.show('leaderboard-results-modal');
      this.leaderboardData = await this.fetchLeaderboardData(tournamentId);
    },
    showTournaments(tournament_id) {
      if(tournament_id == 46) {
        let campaignUUId = ['CAMPAIGN-f8da900b-4bb3-4b0d-8376-274f93d5bd14']
        let isPresent = false;
        for (let i=0; i< this.getAvailablePromotionsContent.length; i++) {
          if(campaignUUId.includes(this.getAvailablePromotionsContent[i].uuid)) {
            isPresent = true;
          }
        }
        return !isPresent;
      }
      return true;
  },
  async claimCampaign(campaignUUID, index, is_deposit, redirectToAfterClaim, is_playnow) {
      if (redirectToAfterClaim) {
        this.redirectToAfterClaim = redirectToAfterClaim;
      }
      this.claimPromoSpinnerIndex = index
      if(this.getActiveBonusData.playerUUID === '') {
        let result = await this.claimPromotionCampaign(campaignUUID)
        this.$toast.open({
          message: this.$t(result.message),
          duration: 5000,
          type: result.success ? 'success' : 'error',
          position: 'top-right'
        })
        if(result.success) {
          setTimeout(async ()  => {
            await this.getPastBonuses()
            await this.getFreeSpinsCampaignWise(campaignUUID)
            await this.getActiveBonuses()
            await this.getAvailablePromotions(cookies.get('banner_id'))
            this.goToActive()
            this.$forceUpdate();
            this.claimPromoSpinnerIndex = -1
            if(!is_playnow) {
              if(is_deposit) {
                this.showIframeDepositPopup()
              } else {
                this.openFreeSpinsModal()
              }
            } else {
              window.location.href = this.redirectToAfterClaim;
            }
          }, 1000);
        } else {
          this.claimPromoSpinnerIndex = -1
          this.$toast.open({
            message:result.error,
            type: 'error',
            position: 'top-right'
          })
        }
      } else {
        this.$toast.open({
          message:'At a time only one promotion can be active',
          type: 'error',
          position: 'top-right'
        })
      }
    },
    openFreeSpinsModal() {
      let element = this.$refs.freeSpinModal
      element.classList.add('show')
    },
    closeFreeSpinsModal() {
      let element = this.$refs.freeSpinModal
      element.classList.remove('show')
      window.location.href = this.redirectToAfterClaim;
    },
    getEndDate(date, days) {
      var new_date = moment(date,'YYYY-MM-DDTHH:mm:ss.SSSS').add(days, 'days')
      return new_date.format('YYYY-MM-DD HH:mm:ss')
    },
    getUserUnderstandableDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    goToActive() {
      this.$refs['tab-content'].querySelector('.tab-pane.active').classList.remove('active')
      this.$refs['tab-content'].querySelector('#active_promo').classList.add('active')
      this.$refs['nav-link'].querySelector('li > .nav-link.active').classList.remove('active')
      this.$refs['nav-link'].querySelector('#active_promo_link').classList.add('active')
    },
    async showIframeDepositPopup() {
      if (this.limitedAccount) {
        this.$bvModal.show('new-restricted-region-modal')
      } else {
        // eslint-disable-next-line
        ($('#pre_new_deposit_dialog') as any).modal('show');
        await this.getDepositAddress();
      }
    },
    checkIsActivePromotion(authorUUID, index) {
      if(this.getActiveBonusAuthorUUID === authorUUID) {
        this.deletePastPromo(index)
        return false
      }
      return true
    },
    checkIsClaimable(claimAfter) {
        for(let i = 0; i < this.getAvailablePromotionsContent.length; i++) {
            if(this.getAvailablePromotionsContent[i].uuid == claimAfter) {
              return true;
            }
        }
        return false;
    },
    getAvailablePromo() {
      this.getAvailablePromotions(cookies.get('banner_id'));
    },
    async getTempXMasPromotion() {
      let xMasCampaignUUID = ['CAMPAIGN-cb765f90-c952-4d79-960d-c17223df4725', 'CAMPAIGN-05a6a6f5-527e-4050-b3d7-6c8975ec8a64'];
      let isPresent = false;
      for(let i=0; i< this.getAvailablePromotionsContent.length; i++) {
        if(xMasCampaignUUID.includes(this.getAvailablePromotionsContent[i].uuid)) {
          isPresent = true;
        }
      }
      if(!isPresent) {
        let banner_id = cookies.get('banner_id');
        if(banner_id === undefined) {
          banner_id = 'default';
        }
        const response = await apolloClient.query({
          query: gql`
              query Promos($casinoCode: String!, $status: String!, $bannerId: String!) {
                casinos(where: { name: $casinoCode }) {
                  name
                  promotions(
                    where: { status: $status, advertisements: { banner_id: $bannerId } }
                  ) {
                    id
                    index
                    description
                    description_ru
                    description_es
                    description_pt
                    wager_requirement
                    campaign_name
                    requirements
                    terms_and_condition
                    terms_and_condition_ru
                    terms_and_condition_es
                    terms_and_condition_pt
                    buttonText
                    buttonText_ru
                    buttonText_es
                    buttonText_pt
                    auto_opt_in
                    redirectToAfterClaim
                    uuid
                    status
                    is_deposit
                    is_playnow
                    is_freespins
                    is_disabled
                    advertisements(where: { banner_id: $bannerId }) {
                      banner_id
                    }
                    image {
                      formats
                    }
                  }
                }
              }
          `,
          variables: {
            status: process.env.VUE_APP_WMS_URL.includes("btctest.net") ? "draft" : "published",
            casinoCode: cookies.get('casino_code'),
            bannerId: banner_id
          },
          fetchPolicy: "no-cache"
        });
        let casino_promo_data = response.data.casinos.find(c => c.name == cookies.get('casino_code'))
        let xMasActivePromo = casino_promo_data.promotions.filter(function (e) {
            return xMasCampaignUUID.includes(e.uuid);
        });
        if(xMasActivePromo.length > 0) {
          let activePromo = xMasActivePromo[0];
          if(this.getActiveBonusData.playerUUID === '') {
            this.getActiveBonusData['playerUUID'] = cookies.get('userId');
            this.getActiveBonusData['image'] = activePromo.image;
            this.getActiveBonusData['description'] = activePromo.description;
            this.getActiveBonusData['terms_and_condition'] = activePromo.terms_and_condition;
          }
        }
      }
    },
    // Currently only checking, is there any deposit after campaign opt-in or not.
    // Based on that showing the "Waiting For Deposit" button
    async checkWaitingForDepositsCampaigns() {
      for(let i = 0; i < this.getAvailablePromotionsContentWithOptInDate.length; i++) {
        let currency = this.getPlatformCurrency;
        let promo = this.getAvailablePromotionsContentWithOptInDate[i]
        let minDepositAmount = promo['min_deposit_' + currency];
        let maxDepositAmount = promo['max_deposit_' + currency];

        if (promo.is_deposit) {
          Vue.set(this.getAvailablePromotionsContentWithOptInDate[i], 'is_waiting_for_deposits', true);
          await this.getDeposits(this.getAvailablePromotionsContentWithOptInDate[i].optInDate);
          if (this.depositHistory.length > 0) {
            if (minDepositAmount || maxDepositAmount) {
              for (let j = 0, len = this.depositHistory.length; j < len; j++) {
                if (this.depositHistory[j].status === 'COMPLETED') {
                  let depositAmount = this.depositHistory[j]['amount']['amount'];
                  // If min value is not defined then still use the max value for checking, and vice versa
                  if ((!minDepositAmount || depositAmount >= minDepositAmount) && (!maxDepositAmount || depositAmount <= maxDepositAmount)) {
                    Vue.set(this.getAvailablePromotionsContentWithOptInDate[i], 'is_waiting_for_deposits', false);
                  }
                }
              }
            } else {
              Vue.set(this.getAvailablePromotionsContentWithOptInDate[i], 'is_waiting_for_deposits', false);
            }
          }
        } else {
          Vue.set(this.getAvailablePromotionsContentWithOptInDate[i], 'is_waiting_for_deposits', false);
        }
      }
    },
    async getDeposits(startDate) {
      const payload = {
        'startDate': startDate,
        'getAll': false,
        'platformCurrency': this.getPlatformCurrency
      }
      await this.getDepositsData(payload);
    },
    redirectToSignUp() {
      this.$router.push('/signup')
    },
    async checkSpecificDayPromotion() {
      for(let i = 0; i < this.getAvailablePromotionsContent.length; i++) {
        let promo = this.getAvailablePromotionsContent[i]

        if(promo.is_only_on_specific_day) {
          const today = new Date().getUTCDay();
          const promoDay = this.weekDays[promo.specific_day];

          Vue.set(this.getAvailablePromotionsContent[i], 'is_day_specific', today === promoDay);
        }
      }
    }
  },
  }
);

@Component({
components: {
  VueMarkdownIt,
  VueMarkdown,
  Tournaments
},
})
export default class Promotion extends AppProps {
  @SettingsGetter getPlatformCurrency!: String
  @AccountAction getUserData
  @AccountAction getDepositAddress
  @AccountGetter emailVerified
  @AccountGetter isLoggedIn: boolean
  @BonusWagerGetter getBonusClaimTotalWager
  @BonusWagerGetter getBonusClaimMaxWager
  @BonusWagerGetter getIsAvailableBonus
  @AvailablePromotionsCampaignGetter getAvailablePromotionsContent
  @AvailablePromotionsCampaignGetter getAvailablePromotionsContentWithoutOptInDate
  @AvailablePromotionsCampaignGetter getAvailablePromotionsContentWithOptInDate
  @AvailablePromotionsCampaignGetter getAutoOptInCampaign
  @AvailablePromotionsCampaignAction claimPromotionUsingBannerId
  @AvailablePromotionsCampaignAction getAvailablePromotions
  @AvailablePromotionsCampaignAction getGeneralPromotions
  @AvailablePromotionsCampaignAction claimPromotionCampaign
  @AvailablePromotionsCampaignAction fetchLeaderboardData
  @FreeSpinsAction getFreeSpinsCampaignWise
  @FreeSpinsGetter getFreeSpinsConnectedWithCampaign
  @ActivePromotionsCampaignAction getActiveBonuses
  @ActivePromotionsCampaignGetter getActiveBonusData
  @ActivePromotionsCampaignGetter getActiveBonusAuthorUUID
  @ActivePromotionsCampaignGetter isAnyPromotionActive
  @PastPromotionsCampaignAction getPastBonuses
  @PastPromotionsCampaignAction deletePastPromo
  @PastPromotionsCampaignGetter getPastBonusData
  @DepositAction("getDeposits") getDepositsData
  @DepositGetter depositHistory
}
